/* eslint-disable no-control-regex */
/* eslint-disable no-unused-vars */

import { onMounted, onUnmounted, ref } from 'vue';

export const isScrollBottom = (element) => Math.abs(element.scrollHeight - element.clientHeight - element.scrollTop) <= 5;

// Maps the IRC colours mIRC uses to a html value
const ircColourMap = {
    "00": "#ffffff",
    "01": "#000000",
    "02": "#00007f",
    "03": "#009300",
    "04": "#ff0000",
    "05": "#7f0000",
    "06": "#9c009c",
    "07": "#fc7f00",
    "08": "#ffff00",
    "09": "#00fc00",
    "10": "#009393",
    "11": "#00ffff",
    "12": "#0000fc",
    "13": "#ff00ff",
    "14": "#7f7f7f",
    "15": "#d2d2d2",
};

// IRC Color parser
const ircColourNames = [
    "White","Black","Blue","Green","Red","Brown","Magenta","Orange","Yellow","Light Green", "Cyan","Light Cyan","Light Blue", "Pink", "Grey", "Light Grey"
];
const ircColourRegex = /\x03(\d{1,2})(,\d{1,2})?(.*?)(?=\x03|$)/g; 
const ircRevertRegex = new RegExp("\x0f", "g");
const ircStyleChars = ["\x02", "\x1d", "\x1f", "\x1e"]; 
const styleTags = ["<b>", "<i>", "<u>", "<s>"];
const styleClosures = ["</b>", "</i>", "</u>", "</s>"];
 
const escapeHtml = (text) => {
    const entities = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    };
 
    return text.replace(/[&<>"']/g, match => entities[match]);
}
 
const hexToRgb = (hex) => {
    hex = hex.replace('#', '');
 
    // Convert the hex values to RGB
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
 
    return `rgb(${r}, ${g}, ${b})`;
}

export const parseIRCColours = (text) => {
    let insideStyles = new Array(ircStyleChars.length).fill(false);
    const applyStyles = (content) => {
        let styledContent = content;
        for (let i = 0; i < ircStyleChars.length; i++) {
            if (insideStyles[i]) styledContent += styleClosures[i];
        }
        return styledContent;
    };
 
    // remove any style revert chars (ctrl +o) since we don't support it.
    text = text.replace(ircRevertRegex, '');
 
    let htmlText = text.replace(ircColourRegex, (match, textColourCode, backgroundColourCode, content) => {
        const cssStyle = [];
        if (textColourCode) {
            let fgColour = textColourCode;
            if (!fgColour.startsWith("0") && parseInt(fgColour) < 10) fgColour = `0${fgColour}`;
            const textStyle = ircColourMap[fgColour] || "";
            cssStyle.push(`color: ${hexToRgb(textStyle)};`);
        }
        if (backgroundColourCode) {
            let bgColour = backgroundColourCode.substring(1);
            if (!bgColour.startsWith("0") && parseInt(bgColour) < 10) bgColour = `0${bgColour}`;
            const backgroundColourStyle = ircColourMap[bgColour] || "";
            cssStyle.push(`background: ${hexToRgb(backgroundColourStyle)};`);
        }
        return `<span style="${cssStyle.join('')}">${applyStyles(escapeHtml(content))}</span>`;
    });
 
    for (let i = 0; i < ircStyleChars.length; i++) {
        htmlText = htmlText.replace(new RegExp(ircStyleChars[i], "g"), () => {
            insideStyles[i] = !insideStyles[i];
            return insideStyles[i] ? styleTags[i] : styleClosures[i];
        });
    }
 
    htmlText = applyStyles(htmlText); // Apply any remaining style closures
    htmlText = htmlText.replace(new RegExp('\x03', 'g'), ''); // Remove any remaining colour codes
    return htmlText;
}

export const generateUniqueColorFromText = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
}

export const useVisibilityChange = (onHidden, onVisible) => {
    const handleVisibilityChange = () => {
        if (document.visibilityState === 'hidden') {
            onHidden();
        } else {
            onVisible();
        }
    };

    onMounted(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
    });

    onUnmounted(() => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
    });
}

// notification.js or inside useVisibility.js
export const showNotification = (msg) => {
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
        const notification = new Notification(msg);
        notification.onclick = function() {
            window.focus(); // This will focus the tab if the browser supports it
            this.close(); // Closes the notification
        };
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                const notification = new Notification(msg);
                notification.onclick = function() {
                    window.focus(); 
                    this.close(); 
                };
            }
        });
    }
}

export const checkNameSameWithModerator = (aName, bName) => {
    return aName === bName || ('@' + aName) === bName;
}